.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@font-face {
  font-family: "Helvetica Neu Bold";
  src: url('./fonts/Helvetica Neu Bold.ttf');
}

* {
  font-family: "Helvetica Neu Bold"; 
  background-color: black;
  color: white;
  box-sizing: border-box;
}

button {
  border: none;
  margin: none;
  border-radius: 5px;
  font-size: calc(1vmin + 5px)
}

p, .icon {
  background-color: transparent;
  font-size: calc(4px + 1vmin)
}

h1 {
  font-size: calc(12px + 3vmin)
}

h2 {
  font-size: calc(10px + 2.5vmin)
}

h3 {
  font-size: calc(8px + 2vmin)
}

h4 {
  font-size: calc(6px + 1.5vmin)
}

h5 {
  font-size: calc(4px + 1vmin)
}

h6 {
  font-size: calc(2px + 0.5vmin)
}

hr {
  margin: none;
}

#hotkeys {
  height: 100%;
  width: 100%;
}

.title {
  width: 20%;
  margin: 20px 20px 0 20px;
}

.round-corners {
  border-radius: 5px;
}

.alerts {
  height: auto;
  width: 20vw;
  min-width: 250px;
  text-align: justify;
  padding: 10px 20px 20px 20px;
  position: absolute;
  background-color: transparent;
  top: 5%;
  right: 5%;
  z-index: 2
}

.alert {
  background-color: black !important;
  border: red 2px solid;
  border-radius: 10px;
  padding: 10px 20px 20px 20px;
  margin: 5px;
}

.white {
  color: white !important
}

.invert {
  background-color: white;
  color: black;
}

.top {
  text-align: center;
  width: 100%
}

.dimension-container {
  width: 90vw;
  max-width: 1024px;
  margin: auto;
}

.dimension {
  display: grid;
  grid-template-columns: 2fr 1fr 2fr 1fr 1fr 2fr;
  grid-template-rows: 2vh;
  justify-items: center;
  align-items: center;
  font-size: calc(1vmin + 5px);
}

.name {
  margin-left: 5px;
}

.bonus {
  padding: 5px;
}

.amount {
  padding: 5px;
}

.autobuy {
  padding: 5px;
}

.price {
  margin-right: 5px;
}

.progress-bar {
  width: 90vw;
  max-width: 1536px;
  margin: 50px auto;
}

.tabs {
  display: flex;
  flex-direction: row;
  align-content: flex-end;
  justify-content: space-around;
  position: relative;
  width: auto;
  height: auto;
  font-size: 1.5vh !important;
  margin: 2vh auto 1vh auto;
  padding-left: min(30%);
  padding-right: min(30%);
}

.sub-header {
  position: flex;
  margin: 20px auto;
  display: flex;
}

.container {
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.subtabs {
  display: flex;
  flex-direction: row;
  align-content: flex-end;
  justify-content: space-around;
  position: relative;
  width: auto;
  height: auto;
  font-size: 0.75vh !important;
  margin-bottom: 1vh;
  margin-left: auto;
  margin-right: auto;
  padding-left: min(40%);
  padding-right: min(40%);
}

.popup {
  height: 30%;
  width: 30%;
  padding: 10px 20px 20px 20px;
  overflow: scroll;
  text-align: left;
  border: 2px solid white;
  border-radius: 10px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.popup::-webkit-scrollbar {
  display: none;
}

.close {
  position: sticky;
  top: 10px;
  width: 100%;
  text-align: right;
  background: none;
}

.icon {
  height: 20px;
  width: 20px;
}

.grey {
  background-color: #808080;
}

.label {
  display: flex;
  justify-content: center;
}

.sacrifice {
  max-width: 30vw;
  margin: 50px auto;
  padding: 20px;
  min-height: 100px;
  min-width: 600px;
  text-align: center;
  border-radius: 10px;
  padding: 20px;
}

.milestone-grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  margin: 20px auto;
  justify-content: center;
  width: 80vw;
  max-width: 1024px;
  column-gap: 0
}

.milestone {
  height: 20vw;
  width: 20vw;
  max-height: 256px;
  max-width: 256px;
  position: relative;
  display: flex;
  justify-content: center;
}

.milestone-img {
  height: auto;
  width: auto;
  max-height: 10vw;
  max-width: 10vw;
  margin: auto
}

.milestone-label-text, .milestone-description-text {
  margin: 0;
  text-align: center;
  background-color: transparent 
}

.milestone-label, .milestone-description {
  margin: 0;
  position: absolute;
  left: 50%;
  width: 90%;
  transform: translate(-50%, 0%);
  z-index: 1;
  background-color: transparent 
}

.milestone-label {
  top: 0.5vh;
}

.milestone-description {
  bottom: 1vh;
}

.milestone-indicator {
  height: 100%;
  width: 100%;
  margin: 0;
  position: absolute;
  z-index: 2;
}

.big-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin: 20px auto;
  width: 50%;
  min-width: 800px;
  max-width: 1600px;
  column-gap: 10px;
  row-gap: 10px;
}

.big {
  min-height: 200px;
  text-align: center;
  border-radius: 10px;
  padding: 20px;
  position: relative;
}

.medium-grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  margin: 20px auto;
  width: 50%;
  min-width: 400px;
  max-width: 800px;
  column-gap: 5px;
  row-gap: 5px;
}

.prestige-grid {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 20px auto;
  width: 50%;
  min-width: 400px;
  max-width: 800px;
  column-gap: 5px;
  row-gap: 5px;
}

.prestige {
  min-height: 100px;
  text-align: center;
  border-radius: 10px;
  padding: 20px;
  position: relative;
  width: 20vw
}

.medium {
  min-height: 100px;
  text-align: center;
  border-radius: 10px;
  padding: 20px;
  position: relative;
  max-width: 12.5vw
}

.lower {
  z-index: -1
}

.reset-colors {
  display: flex;
  width: 50vw;
  margin: auto;
  max-width: 500px;
  justify-content: center;
  align-items: center;
}

.challengebutton {
  position: absolute;
  border: 2px;
  margin-bottom: 5px;
  border-radius: 2px;
  left: 50%;
  bottom: 5%;
  transform: translateX(-50%); 
}

.chapter {
  margin-bottom: 20px;
  margin-left: 25%;
  margin-right: 25%;
  width: 50%;
  text-align: center;
  padding-left: 20px;
  padding-right: 20px;
}

.accordion-label {
  height: auto;
  font-size: 1.5vh;
}

.panel {
  display: none;
  overflow: hidden;
}

.colorinput {
  padding: 20px;
  margin-bottom: 20px;
  width: 25%;
  display: grid;
  grid-template-columns: 1fr 2fr 1fr;
  margin: auto;
  column-gap: 10px;
  row-gap: 10px;
}

.setting-grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  margin: auto 25%;
  column-gap: 10px;
  row-gap: 10px;
}

.setting {
  text-align: center;
  border-radius: 10px;
}

.marquee {
  animation: marquee 5s linear infinite;
}

.center {
  margin: auto;
  width: 100%;
}

.grandgrav, .locked {
  margin: auto;
  text-align: center;
  font-size: calc(1vmin + 5px);
  height: max;
  width: 25%;
  border: solid white 2px;
  border-radius: 10px;
  margin: 50px auto;
}

.grandgrav-button {
  padding: 5px;
  border-radius: 5px;
  margin: 5px;
}

.objekt {
  width: 100%;
  vertical-align: middle;
  margin: auto;
}

.objekt-grid {
  margin: 0 25%;
  width: 50%
}

@keyframes marquee{
  0% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(100%);
  }
}
